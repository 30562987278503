.profile {
    background-color: #141414;
    color: #fff
}
.profile .name {
    font-size: 2em;
}
.user_image {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #fff;
}

.user_image img {
    width: 100%;
    height: auto;
    transition: all 0.2s linear;
}

.description {
    flex: 1;
}
.contact_item {
    margin-left: 1em;
}

.profile_form input[type='text'],
.profile_form textarea,
.profile_form select {
  width: 100%;
}
.formControl.error input,
.formControl.error textarea { border-color: #ce0303; }

.formControl.error .formControlText,
.formControlTextError { display: block; font-size: 0.750em; color: #ce0303; }.formControl.error input,
.formControl.error textarea { border-color: #ce0303; }

.formControl.error .formControlText,
.formControlTextError { display: block; font-size: 0.750em; color: #ce0303; }

.user_image:hover .upload_lable {
    cursor: pointer;
    opacity: 1;
} 
.upload_lable {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
    background-color: rgb(0 0 0 / 30%);
    display: grid;
    place-items: center;
}
.upload_file_input {
    display: none;
}
.profile_status { 
    padding: 0.6em 1em; 
    display: inline-block; 
    line-height: 1.3;
    cursor: pointer;
}