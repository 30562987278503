.user_image {
    display: inline-block;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
}

.user_image img {
    width: 100%;
    height: auto;
    transition: all 0.2s linear;
}
.message {
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 1em 0;
}
.message .item {
    background-color: #666;
    padding: .5em 1em;
    color: #fff;
    border-radius: 5px;
}