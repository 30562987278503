.menu_top {
  color: #fff;
  background-color: #141414;
  height: 52px;
  width: 100%;
}
.menu_top a {
  color: #fff;
}
.menu_top ul {
  display: block;
}

.login_block {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  color: inherit;
}
.login_block_image_wrap {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.userpic {
    display: inline-block;
    border-radius: 50%;
    background-color: #fff;
    color: #141414;
    font-size: 2rem;
    height: 1em;
    width: 1em; 
}
