@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

*,*:before,*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}

/* Icon */
@font-face {
  font-family: 'modern';
  src: url('assets/fonts/modern.eot?1t12m3');
  src: url('assets/fonts/modern.eot?1t12m3#iefix') format('embedded-opentype'),
    url('assets/fonts/modern.ttf?1t12m3') format('truetype'),
    url('assets/fonts/modern.woff?1t12m3') format('woff'),
    url('assets/fonts/modern.svg?1t12m3#modern') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'modern' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: '\e917';
}
.icon-phone-in:before {
  content: '\e918';
}
.icon-star:before {
  content: '\e915';
}
.icon-star-in:before {
  content: '\e916';
}
.icon-ruble:before {
  content: '\e912';
}
.icon-radio:before {
  content: '\e913';
}
.icon-checkbox:before {
  content: '\e911';
}
.icon-service-09:before {
  content: '\e914';
}
.icon-service-01:before {
  content: '\e909';
}
.icon-service-08:before {
  content: '\e90a';
}
.icon-service-07:before {
  content: '\e90b';
}
.icon-service-06:before {
  content: '\e90c';
}
.icon-service-05:before {
  content: '\e90d';
}
.icon-service-04:before {
  content: '\e90e';
}
.icon-service-03:before {
  content: '\e90f';
}
.icon-service-02:before {
  content: '\e910';
}
.icon-bottom:before {
  content: '\e905';
}
.icon-top:before {
  content: '\e906';
}
.icon-left:before {
  content: '\e907';
}
.icon-right:before {
  content: '\e908';
}
.icon-close:before {
  content: '\e904';
}
.icon-menu:before {
  content: '\e900';
}
.icon-grid-one:before {
  content: '\e901';
}
.icon-grid-three:before {
  content: '\e902';
}
.icon-grid-two:before {
  content: '\e903';
}

body {
  color: #1f1b20;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.4;
}
h1 {
    font-size: 2em;
}
blockquote {
  margin: 0;
}
nav ul {
  padding: 0;
  margin: 0;
  background-color: inherit;
  list-style: none;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  background-color: inherit;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  padding: 0.5em 1em;
  margin: 0 0.2em;
  color: inherit;
}
nav ul li a.active {
  color: #ff4848;
}
section {
  padding-top: 2em;
  padding-bottom: 2em;
}

.container {
  width: 86%;
  max-width: 900px;
  height: 100%;
  margin: 0 auto;
}
.row:after {
  content: '';
  display: table;
  clear: both;
}
.row {
  margin: 0 -0.75rem;
  /*padding-bottom: 2.5rem;*/
}
.row .col {
  padding: 0 0.75rem;
}
.col {
  display: inline-block; /*float: left;*/
  vertical-align: top;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
}
.s1 {
  width: 8.3333333333%;
}
.s2 {
  width: 16.6666666667%;
}
.s3 {
  width: 25%;
}
.s4 {
  width: 33.3333333333%;
}
.s5 {
  width: 41.6666666667%;
}
.s6 {
  width: 50%;
}
.s7 {
  width: 58.3333333333%;
}
.s8 {
  width: 66.6666666667%;
}
.s9 {
  width: 75%;
}
.s10 {
  width: 83.3333333333%;
}
.s11 {
  width: 91.6666666667%;
}
.s12 {
  width: 100%;
}
@media only screen and (min-width: 601px) {
  .m1 {
    width: 8.3333333333%;
  }
  .m2 {
    width: 16.6666666667%;
  }
  .m3 {
    width: 25%;
  }
  .m4 {
    width: 33.3333333333%;
  }
  .m5 {
    width: 41.6666666667%;
  }
  .m6 {
    width: 50%;
  }
  .m7 {
    width: 58.3333333333%;
  }
  .m8 {
    width: 66.6666666667%;
  }
  .m9 {
    width: 75%;
  }
  .m10 {
    width: 83.3333333333%;
  }
  .m11 {
    width: 91.6666666667%;
  }
  .m12 {
    width: 100%;
  }
}
@media only screen and (min-width: 993px) {
  .l1 {
    width: 8.3333333333%;
  }
  .l2 {
    width: 16.6666666667%;
  }
  .l3 {
    width: 25%;
  }
  .l4 {
    width: 33.3333333333%;
  }
  .l5 {
    width: 41.6666666667%;
  }
  .l6 {
    width: 50%;
  }
  .l7 {
    width: 58.3333333333%;
  }
  .l8 {
    width: 66.6666666667%;
  }
  .l9 {
    width: 75%;
  }
  .l10 {
    width: 83.3333333333%;
  }
  .l11 {
    width: 91.6666666667%;
  }
  .l12 {
    width: 100%;
  }
}

.flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex.middle {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.flex.wrap {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex.full-line {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-separator {
  flex: 1;
}

.price {
  color: inherit;
  font-size: 1.8rem;
}

button,
.button,
input[type='button'],
input[type='submit'] {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  text-decoration: none;
  color: #fff;
  background: #000;
  outline: 0;
  border: 2px solid #000;
  padding: 0.6em 1em;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  appearance: none;
  transition: all 0.3s ease-in-out;
}
a.button:visited {
  color: #fff;
}
a.button:hover {
  color: #000 !important;
}
button:hover,
.button:hover,
input[type='button']:hover,
input[type='submit']:hover {
  color: #000;
  background: #fff;
}
button.active {
  color: #000;
  background: #fff;
}

.decor:before,
.decor:after {
  content: '';
  border-bottom: 2px solid;
  width: 33px;
  height: 0;
  display: block;
  margin: 1rem auto;
}

input[type='tel'],
input[type='search'],
input[type='text'],
input[type='submit'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea,
select {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 1em;
  margin: 0;
  outline: 0;
  border: 1px solid transparent;
  background-color: #F6F6F5;
  padding: .6em 1em;
  box-sizing: border-box;
  border-radius: 2px;
  appearance: none;
}
select {
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='36' height='24'%3e%3cpath fill='none' d='M0 0h36v24H0z'/%3e%3cpath d='M17.541 15.395l-5.259-5.258a.651.651 0 010-.919l.614-.613a.649.649 0 01.917-.001L18 12.771l4.187-4.167a.649.649 0 01.917.001l.614.613a.651.651 0 010 .919l-5.259 5.258a.648.648 0 01-.918 0z' fill='%23a1a1a1'/%3e%3c/svg%3e");
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 36px !important;
}
select option {
  font-size: 0.875rem;
}
input[type='radio' i],
input[type='checkbox' i] {
  margin-right: 0.6em;
}
.input-field {
	position: relative;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.input-field .col { 
    margin-bottom: 0;
}
.input-field .input-field { margin-bottom: 0; }
.field-name {
    margin-bottom: .5rem;
}
textarea {
  width: 100%;
  box-sizing: border-box;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
}


/* Checkboxes
   ========================================================================== */
   [type="checkbox"],
   [type="radio"] {
     -webkit-box-sizing: border-box;
             box-sizing: border-box;
     /* 1 */
     padding: 0;
     /* 2 */
   }   
/* Remove default checkbox */
[type="checkbox"]:not(:checked), [type="checkbox"]:checked { position:absolute; opacity:0; pointer-events:none; }

[type="checkbox"]+ span:not(.lever) { position:relative; padding-left:35px; cursor:pointer; display:inline-block; height:25px; line-height:25px; font-size:1rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none; user-select:none; }
[type="checkbox"]+ span:not(.lever):before, [type="checkbox"]:not(.filled-in) + span:not(.lever):after { content:''; position:absolute; top:0; left:0; width:18px; height:18px; z-index:0; border:2px solid #5A5A5A; border-radius:1px; margin-top:3px;-webkit-transition:.2s; transition:.2s; }
[type="checkbox"]:not(.filled-in) + span:not(.lever):after { border:0;-webkit-transform:scale(0); transform:scale(0); }
[type="checkbox"]:not(:checked):disabled + span:not(.lever):before { border:none; background-color:rgba(0, 0, 0, 0.42); }
[type="checkbox"].tabbed:focus + span:not(.lever):after { -webkit-transform:scale(1); transform:scale(1); border:0; border-radius:50%;-webkit-box-shadow:0 0 0 10px rgba(0, 0, 0, 0.1); box-shadow:0 0 0 10px rgba(0, 0, 0, 0.1); background-color:rgba(0, 0, 0, 0.1); }
[type="checkbox"]:checked + span:not(.lever):before { top:-4px; left:-5px; width:12px; height:22px; border-top:2px solid transparent; border-left:2px solid transparent; border-right:2px solid #26A69A; border-bottom:2px solid #26A69A;-webkit-transform:rotate(40deg); transform:rotate(40deg);-webkit-backface-visibility:hidden; backface-visibility:hidden;-webkit-transform-origin:100% 100%; transform-origin:100% 100%; }
[type="checkbox"]:checked:disabled + span:before { border-right:2px solid rgba(0, 0, 0, 0.42); border-bottom:2px solid rgba(0, 0, 0, 0.42); }
[type="checkbox"]:indeterminate + span:not(.lever):before { top:-11px; left:-12px; width:10px; height:22px; border-top:none; border-left:none; border-right:2px solid #26A69A; border-bottom:none;-webkit-transform:rotate(90deg); transform:rotate(90deg);-webkit-backface-visibility:hidden; backface-visibility:hidden;-webkit-transform-origin:100% 100%; transform-origin:100% 100%; }
[type="checkbox"]:indeterminate:disabled + span:not(.lever):before { border-right:2px solid rgba(0, 0, 0, 0.42); background-color:transparent; }
[type="checkbox"].filled-in + span:not(.lever):after { border-radius:2px; }
[type="checkbox"].filled-in + span:not(.lever):before, [type="checkbox"].filled-in + span:not(.lever):after { content:''; left:0; position:absolute;-webkit-transition:border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s; transition:border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s; z-index:1; }
[type="checkbox"].filled-in:not(:checked) + span:not(.lever):before { width:0; height:0; border:3px solid transparent; left:6px; top:10px;-webkit-transform:rotateZ(37deg); transform:rotateZ(37deg);-webkit-transform-origin:100% 100%; transform-origin:100% 100%; }
[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after { height:20px; width:20px; background-color:transparent; border:2px solid #5A5A5A; top:0; z-index:0; }
[type="checkbox"].filled-in:checked + span:not(.lever):before { top:0; left:1px; width:8px; height:13px; border-top:2px solid transparent; border-left:2px solid transparent; border-right:2px solid #FFFFFF; border-bottom:2px solid #FFFFFF;-webkit-transform:rotateZ(37deg); transform:rotateZ(37deg);-webkit-transform-origin:100% 100%; transform-origin:100% 100%; }
[type="checkbox"].filled-in:checked + span:not(.lever):after { top:0; width:20px; height:20px; border:2px solid #000000; background-color:#000000; z-index:0; }
[type="checkbox"].filled-in.tabbed:focus + span:not(.lever):after { border-radius:2px; border-color:#5A5A5A; background-color:rgba(0, 0, 0, 0.1); }
[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after { border-radius:2px; background-color:#26A69A; border-color:#26A69A; }
[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):before { background-color:transparent; border:2px solid transparent; }
[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):after { border-color:transparent; background-color:#949494; }
[type="checkbox"].filled-in:disabled:checked + span:not(.lever):before { background-color:transparent; }
[type="checkbox"].filled-in:disabled:checked + span:not(.lever):after { background-color:#949494; border-color:#949494; }