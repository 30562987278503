.preload { 
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255 255 255 / 72%);
    z-index: 9;
 }
.preload img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}