.banner {
  background-color: #141414;
  color:#fff;
}
.banner h1 {
  font-size: 3em;
}
.home_price {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.home_price .col {
  display: flex;
}

.home_price .cont {
  height: 100%;
  font-size: 0.9em;
  position: relative;
  background: #fff;
  overflow: hidden;
}

.home_price .cont:hover .block {
  border-color: #000;
  transition: all 0.2s ease-in-out;
}

.home_price .cont .block {
  color: inherit;
  display: block;
  padding: 1em 1em 66px;
  height: 100%;
  border: 2px solid #efefef;
  box-sizing: border-box;
}

.home_price .cont:hover .block {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.2s ease-in-out;
}

.home_price .cont .icon {
  font-size: 80px;
  height: 80px;
  display: block;
  color: inherit;
}

.home_price .cont .lable {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 0.7em;
}

.home_price .cont .lable:before {
  content: "";
  display: block;
  transform: rotate(45deg);
  background-color: #333;
  width: 6em;
  height: 6em;
  position: absolute;
  top: -3em;
  right: -3em;
}

.home_price .cont .lable i {
  font-size: 1.125em;
  position: relative;
}

.home_price .cont:not(.image) a {
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.home_price .cont h2 {
  font-size: 1.3em;
  line-height: 1.2em;
}

.home_price .cont p {
  line-height: 1.4em;
}

.home_price .cont .offers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
}

.home_price .cont.image a {
  display: block;
  text-decoration: none;
  height: 100%;
  line-height: 0;
}

.home_price .cont.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-fit-object: cover;
}

.home_price.small_cat {
  margin-top: 2rem;
}

.home_price.small_cat .cont .block {
  padding: 1em;
}
